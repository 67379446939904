<template>
  <div class="vue_container">
    <!-- Fixed Menu -->
    <div class="ui top fixed hidden menu fixed-menu">
      <div class="ui container">
        <!-- <div class="active item spot-type">{{ spotTypeOnMenu }}</div> -->

        <div class="item location-name">
          <div class="ui equal width center aligned padded grid">
            <!-- <div class="one column">  -->
            <div class="ui header">Top {{ spots.length }}</div>
            <!-- </div> -->
            <!-- <div class="two column">  -->
            {{ locationNameOnMenu }} 的 {{ spotTypeOnMenu }}
            <!-- </div> -->
          </div>
        </div>

        <div class="right menu">
          <!--
          <div class="item">
            <a class="ui button">登入</a>
          </div>
           -->
          <!-- <div class="item">
            <a class="ui black basic button">登入</a>
          </div> -->
        </div>
      </div>
    </div>

    <!-- Sidebar Menu -->
    <!-- <div class="ui vertical inverted sidebar menur">
      <a class="active item">Home</a>
      <a class="item">Work</a>
      <a class="item">Company</a>
      <a class="item">Careers</a>
      <a class="item">Login</a>
      <a class="item">Signup</a>
    </div> -->

    <!-- Page Contents -->
    <!-- <div class="pusher"> -->
    <div class="ui vertical masthead center aligned segment top-container">
      <div class="ui container">
        <div class="ui large secondary menu">
          <!-- <a class="toc item">
              <i class="sidebar icon"></i>
            </a> -->

          <div class="ui aligned padded grid">
            <img class="ui tiny image" src="@/assets/flow-rank-logo-svg.svg" />
            <img
              class="ui samll image ttrank-text"
              src="@/assets/text-logo.svg"
            />
          </div>

          <div class="right item">
            <!-- <div
              class="ui icon top right pointing dropdown sidebar-dropdown-button"
            >
              <input type="hidden" name="category-test" />
              <i class="sidebar icon"></i>
              <div class="menu">
             
                <div class="item" @click="aboustUsClick">
                  <i class="address card icon"></i> 關於我們
                </div>
                <div class="item" @click="feedbackClick">
                  <i class="facebook icon"></i> FB 紛絲團
                </div>
                <div class="header">版本 1.0.0</div>
              </div>
            </div> -->
          </div>

          <div class="ui secondary menu">
            <a class="item"> 十大必吃 </a>
            
            <a class="item">
              <div class="ui dropdown">
                捷運找美食
                
                <div class="menu">
               
                   <div class="item" @click="aboustUsClick">
                     <i class="subway icon"></i>  淡水信義線
                  </div>
                  <div class="item" @click="feedbackClick">
                     <i class="subway icon"></i>  板南線
                  </div>
                  
                </div>
              </div>
            </a>
            <div class="right menu">
              <div class="item">

                <div class="ui action input"  style="height:35px;" >
                  <input type="text search-input" placeholder="開始搜尋美食..."  id="myInput"/>
                  <button class="ui icon button" style="height:35px;margin-left: 0.0em;margin-top: 0em;" @click="searchClick">
                    <i class="search icon"></i>
                  </button>
                </div>
                
              </div>

              <div class="item">
                <div class="ui dropdown">
                  <i class="sidebar icon"></i>
                  <div class="menu">
                  
                    <div class="item" @click="feedbackClick">
                      <i class="facebook icon"></i> FB 紛絲團
                    </div>
                    <div class="header">版本 1.0.0</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="ui text container">
        <h1 class="ui header">探索 IG 上的潛力店家</h1>
        <h2>聰明挑出網紅景點與美食</h2>
      </div>
      <div class="ui hidden divider"></div>
      <div class="ui container">
        <div class="ui equal width grid">
          <div class="centered row">
            <!-- <div class="ui menu"> -->
            <!-- 
            <a class="ui basic label">
    2,048
  </a> -->
            <!-- <div class="ui header" style="padding-top:15px; padding-right:10px" > 台北 </div> -->

            <div class="item">
              <div
                class="ui dropdown top-menu-dropdown button"
                style="margin: 5px; padding-top: 1.1em"
              >
                <input type="hidden" name="category-test" />

                <div class="text">{{ spotTypes[0].name }}</div>
                <i class="dropdown icon"></i>

                <div class="menu">
                  <!-- https://stackoverflow.com/questions/53737648/how-get-clicked-item-in-vue -->

                  <div
                    class="item"
                    v-for="item in spotTypes"
                    :key="item.value"
                    @click="handleDropdownSpotTypeItem(item)"
                  >
                    {{ item.name }}
                  </div>
                  <!-- <div class="item" item="1" @click="onChange(item)">美食</div>
                <div class="item" item="0" @click="onChange">景點</div> -->
                </div>
              </div>
            </div>

            <div class="item">
              <div
                class="ui dropdown button top-menu-dropdown"
                id="top-menu-location-dropdown"
                style="margin: 5px"
              >
                <div
                  class="ui label city-label"
                  style="
                    padding-right: 1em;
                    margin-right: 1em;
                    margin-left: -0.5em;
                  "
                >
                  {{ selectedCityOnMenu }}
                </div>

                <!-- <input type="hidden" name="gender-test" /> -->

                <div class="text">
                  <!-- {{ selectedCityOnMenu + " - " }} -->
                  {{ locationByCity[locationNames[0]][0].name }}
                </div>
                <i class="dropdown icon"></i>

                <div class="menu">
                  <div
                    class="item"
                    v-for="city in locationNames"
                    :key="city.value"
                  >
                    <i class="dropdown icon"></i>
                    {{ city }}
                    <div class="menu">
                      <div
                        class="item"
                        v-for="item in locationByCity[city]"
                        :key="item.value"
                        @click="handleDropdownLocationsItem(city, item)"
                      >
                        {{ item.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- </div> -->

            <!-- <div class="ui menu"> -->
            <!-- <div class="ui vertical divider"></div>
            <div class="ui header"> 台北 </div> -->

            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>

    <div v-if="spinner" id="hide">
      <div class="ui container" style="padding-top: 1em; padding-bottom: 4em">
        <!-- <div class="ui segment">
          <div class="ui active inverted dimmer"> -->
        <!-- <div class="ui active inline loader"></div> -->
        <!-- <div class="ui active text loader spinner"></div> -->
        <div class="ui active centered inline loader"></div>
        <!-- </div> -->
        <!-- <img class="ui image" src="@/assets/mock-list.png" />
        </div>       -->
      </div>
    </div>
    <div class="ui container list-table">
      <div class="ui relaxed divided items">
        <div
          class="item"
          v-for="(item, index) in spots"
          :key="item._id"
          @mouseover="mouseOverSpotItem(item, index)"
          @mouseleave="mouseLeaveSpotItem"
        >
          <!-- <a  :href="item.origin_post_image_link">  -->
          <!-- <div class="ui center">分數</div> -->
          <a
            class="ui small"
            style="padding-left: 1.5em; padding-top: 1em; padding-bottom: 1em"
            :data-tooltip='"圖片作者: "+item.ig_author' data-inverted=""
            @click="spotImageClick(item)"
          >


          <div style="position: relative;margin-right: 50px;height: 145px;width: 150px;overflow: hidden;">
            <iframe width="150" height="200" :src="item.ig_post_url_embed" frameborder="0" style="position: absolute; top: -54px;"></iframe>
          </div>


          </a>
          
          <div class="content">
            <a
              class="header item-header-text"
              style="padding-top: 1em"
              :href="item.ig_location_url"
              target="_blank"
            >
            
              <i class="instagram icon"></i>
              {{ item.title }}            
            </a>


            <div class="meta" style="margin-top: 1rem">
              <a @click="clickAddresLink(item)">
                <i class="map icon"></i>
                {{ item.address }}
              </a>

              <br />
              <br />

              <a
                v-if="item.officail_site != null"
                @click="cickGoogleRatingButton(item)"
              >
                <i class="google icon"></i>
                評價 {{ item.google_rating }} 顆星
              </a>

              <div
                class="ui right floated text go-to-button"
                @click="cickGoButton(item)"
              >
                <i class="fire icon"></i>
                No. {{ index + 1 }}
              </div>

              <br />
              <br />
              <a
                v-if="item.officail_site != null"
                @click="clickOfficialSite(item)"
              >
                <i class="home icon"></i>
                官方網站
              </a>

              <br />
              <br />
            </div>
           

           

            <div class="extra">
              <div
                class="ui label"
                v-for="(tagName, tagIndex) in item.tags"
                :key="tagIndex"
              >
                {{ tagName }}
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <!-- content -->
    <div class="ui vertical segment">
      <div class="ui middle aligned stackable grid container">
        <!-- <div class="row"> -->
        <div
          class="center aligned column more-button"
          style="padding-top: em; padding-bottom: 5em"
        >
          <!-- <a class="ui large button">看更多</a> -->
        </div>
        <!-- </div> -->
      </div>
    </div>

    <div class="ui vertical stripe quote segment">
      <div class="ui equal width stackable internally celled grid">
        <div class="center aligned row">
          <div class="column">
            <div class="ui equal width center aligned padded grid">
              <div class="row">
                <!-- <div class="item"> -->
                <h3>
                  <i class="bell outline icon"></i>
                  每週定時更新
                </h3>
              </div>
              <!-- <div class="item"> -->
              <div class="row">
                <p>
                  社群平台與網路上的大量資訊，透過收集與整理，清楚找到網紅景點
                </p>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="ui equal width center aligned padded grid">
              <div class="row">
                <h3>
                  <i class="chart line icon"></i>
                  透過美食引用的熱度
                </h3>
              </div>
              <div class="row">
                <p>上千家餐廳與景點，推薦出前 100 內的名美食與景點</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="ui vertical stripe segment">
      <div class="ui text container">
        <!-- <a> -->
        <h3 class="ui header up-comming">
          <i class="conversation icon"></i>
          意見回饋
        </h3>
        <!-- </a> -->
        <p>有任何想法與建議歡迎到 Facebook 粉絲團與我們聯絡</p>
        <a
          class="ui large button"
          href="https://www.facebook.com/ttrankapp"
          target="_blank"
          >前往</a
        >
      </div>
    </div>

    <!-- Footer -->
    <div class="ui inverted vertical footer segment">
      <div class="ui container">
        <div class="ui stackable inverted divided equal height stackable grid">
          <div class="four wide column">
            <h4 class="ui inverted header">關於</h4>
            <div class="ui inverted link list">
              <a class="ui item" id="about_us_link" @click="aboustUsClick"
                >關於我們</a
              >
              <a class="ui item" href="/privacy.html"> 隱私權</a>
              
              

              <div class="ui modal about-us-model">
                <i class="close icon"></i>
                <div class="header">關於我們</div>
                <img
                  class="ui samll image large-icon"
                  src="@/assets/large-logo.png"
                />
                <div class="image content">
                  <div class="description">
                    我們是
                    TTRank，喜歡到處吃美食與探訪景點，提供一個平台能夠發掘
                    instagram (ig) 上的美食與景點，歡迎你/妳來 🍔🍜🥂
                  </div>
                </div>
                <!-- <div class="actions">
                  
                  <div class="ui button">OK</div>
                </div> -->
              </div>
              <!-- <a href="#" class="item">關於我們</a> -->
              <!-- 
              <a href="#" class="item">Contact Us</a>
              <a href="#" class="item">Religious Ceremonies</a>
              <a href="#" class="item">Gazebo Plans</a>
              -->
            </div>
          </div>

          <!-- 
          <div class="three wide column">
            <h4 class="ui inverted header">幫助</h4>
            <div class="ui inverted link list">
              <a href="#" class="item">FAQ</a>
            </div>
          </div>
          -->
          <div class="three wide column">
            <h4 class="ui inverted header">社群</h4>
            <div class="ui inverted link list">
              <!-- <a class="item socail-link" href="#">
                <i class="instagram icon"></i> Instagram                
              </a> -->

              <a
                class="item socail-link"
                href="https://www.facebook.com/ttrankapp"
                target="_blank"
                style="margin-top: 1em"
              >
                <i class="facebook icon"></i> Facebook
              </a>

              <!-- <a class="item socail-link" href="#" style="color: #ffffff80">
                <i class="youtube icon"></i> Youtube
              </a> -->

              <!-- <a class=" socail-link" href="#"  style="color:#ffffff80">
                <i class="facebook  icon"></i> Facebook
              </a>

              <a class="socail-link" href="#"  style="color:#ffffff80">
                <i class="instagram icon"></i> Instagram
              </a>

              <a class="socail-link" href="#" style="color:#ffffff80"> 
                <i class="youtube icon"></i> Youtube
              </a> -->
            </div>

            <!-- <p>
              Extra space for a call to action inside the footer that could help
              re-engage users.
            </p> -->
          </div>
        </div>
      </div>
      <div class="ui container copy-right-item">
        <!-- <div class="ui centered grid"> -->
        <div class="ui aligned column">Copyright © 2021 TTRank Inc</div>

        <!-- </div> -->
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
<script src="https://semantic-ui.com/dist/components/sidebar.js"></script>
<script src="https://semantic-ui.com/dist/components/visibility.js"></script>

<script>
window.onload = function () {
  $(".ui.dropdown").dropdown();
};

// dropdown issue
// https://github.com/Semantic-Org/Semantic-UI/issues/5516

$(document).ready(function () {
  // fix menu when passed
  $(".masthead").visibility({
    once: false,
    onBottomPassed: function () {
      $(".fixed.menu").transition("fade in");
    },
    onBottomPassedReverse: function () {
      $(".fixed.menu").transition("fade out");
    },
  });

  // create sidebar and attach to menu open
  // $(".ui.sidebar").sidebar("attach events", ".toc.item");
});

import axios from "axios";

export default {
  name: "HomeRank",
  props: {
    msg: String,
  },
  data() {
    return {
      spinner: false,

      locationNames: ["台北市", "新北市", "台南市"],

      locationByCity: {
        台北市: [
          {
            value: 1,
            areaCode: 100,
            name: "中正區",
          },

          {
            value: 3,
            areaCode: 103,
            name: "大同區",
          },
          {
            value: 4,
            areaCode: 104,
            name: "中山區",
          },
          {
            id: 5,
            value: 5,
            areaCode: 105,
            name: "松山區",
          },
          {
            value: 6,
            areaCode: 106,
            name: "大安區",
          },
          {
            value: 7,
            areaCode: 108,
            name: "萬華區",
          },
          {
            value: 8,
            areaCode: 110,
            name: "信義區",
          },
          {
            value: 9,
            areaCode: 111,
            name: "士林區",
          },
          {
            value: 10,
            areaCode: 112,
            name: "北投區",
          },
          {
            value: 11,
            areaCode: 114,
            name: "內湖區",
          },
          {
            value: 12,
            areaCode: 115,
            name: "南港區",
          },
          {
            value: 13,
            areaCode: 116,
            name: "文山區",
          },
        ],
        台南市: [
          {
            value: 1,
            areaCode: 701,
            name: "東區",
          },
          {
            value: 2,
            areaCode: 700,
            name: "中西區",
          },
          {
            value: 3,
            areaCode: 702,
            name: "南區",
          },
          {
            value: 4,
            areaCode: 704,
            name: "北區",
          },
          {
            value: 5,
            areaCode: 710,
            name: "永康區",
          },
        ],
        新北市: [
          {
            value: 1,
            areaCode: 235,
            name: "中和區",
          },
          {
            value: 2,
            areaCode: 236,
            name: "土城區",
          },
          {
            value: 3,
            areaCode: 231,
            name: "新店區",
          },
          {
            value: 4,
            areaCode: 242,
            name: "新莊區",
          },
          {
            value: 5,
            areaCode: 220,
            name: "板橋區",
          },
          {
            value: 6,
            areaCode: 234,
            name: "永和區",
          },
          {
            value: 7,
            areaCode: 251,
            name: "淡水區",
          },
          {
            value: 8,
            areaCode: 239,
            name: "鶯歌區",
          },
          {
            value: 9,
            areaCode: 247,
            name: "蘆洲區",
          },
          {
            value: 10,
            areaCode: 241,
            name: "三重區",
          },
          {
            value: 11,
            areaCode: 237,
            name: "三峽區",
          },
          {
            value: 12,
            areaCode: 233,
            name: "烏來區",
          },
          {
            value: 13,
            areaCode: 221,
            name: "汐止區",
          },
        ],
      },
      locations: [
        {
          value: 1,
          areaCode: 100,
          name: "中正區",
        },

        {
          value: 3,
          areaCode: 103,
          name: "大同區",
        },
        {
          value: 4,
          areaCode: 104,
          name: "中山區",
        },
        {
          id: 5,
          value: 5,
          areaCode: 105,
          name: "松山區",
        },
        {
          value: 6,
          areaCode: 106,
          name: "大安區",
        },
        {
          value: 7,
          areaCode: 108,
          name: "萬華區",
        },
        {
          value: 8,
          areaCode: 110,
          name: "信義區",
        },
        {
          value: 9,
          areaCode: 111,
          name: "士林區",
        },
        {
          value: 10,
          areaCode: 112,
          name: "北投區",
        },
        {
          value: 11,
          areaCode: 114,
          name: "內湖區",
        },
        {
          value: 12,
          areaCode: 115,
          name: "南港區",
        },
        {
          value: 13,
          areaCode: 116,
          name: "文山區",
        },
      ],
      spotTypes: [
        {
          id: 0,
          index: 0,

          spotTypeCode: "food",
          name: "美食",
        },
        {
          id: 1,
          index: 1,

          spotTypeCode: "cafe",
          name: "咖啡廳",
        },
        {
          id: 2,
          index: 2,

          spotTypeCode: "bar",
          name: "餐酒館 / Bar",
        },
      ],

      // taipeiAreaCode: {
      //   100: "中正區",
      //   103: "大同區",
      //   104: "中山區",
      //   105: "松山區",
      //   106: "大安區",
      //   108: "萬華區",
      //   110: "信義區",
      //   111: "士林區",
      //   112: "北投區",
      //   114: "內湖區",
      //   115: "南港區",
      //   116: "文山區",
      // },
      spots: {},
      selectedLocation: null,
      spotTypeOnMenu: "",
      locationNameOnMenu: "",
      selectedCityOnMenu: "",
      // selectedLocation: null,
      selectedSpotTypeItem: null,
      currentAreaCode: "100",
    };
  },
  beforeMount() {
    this.fetchSpot();

    // $(".menu-spottype-button")
    //   .dropdown("setup menu", {
    //     values: this.spotTypes,
    //   })
    //   .dropdown("set selected", "1");
  },
  mounted() {
    // $(".spot-type").hide();
    // $(".location-name").hide();
  },
  watch: {
    $route: function (to, from) {
      console("update");
    },
  },
  methods: {
    fetchSpot() {
      this.spinner = true;
      var areaCode = 0;
      if (this.selectedLocation != undefined && this.selectedLocation != null) {
        this.locationNameOnMenu =
          this.selectedCityOnMenu + " " + this.selectedLocation.name;
        areaCode = this.selectedLocation.areaCode;
      } else {
        // First time call API
        let city = this.locationNames[0];
        this.selectedCityOnMenu = city;
        this.locationNameOnMenu =
          this.selectedCityOnMenu + " " + this.locationByCity[city][0].name; // locations[0].name;
        this.spotTypeOnMenu = this.spotTypes[0].name;
        areaCode = this.locationByCity[city][0].areaCode;
      }

      let limit = 100;
      var url =
        "https://flow-server.vercel.app/rank" +
        "?limit=" +
        limit +
        "&area_code=" +
        areaCode;

      if (
        this.selectedSpotTypeItem != null &&
        this.selectedSpotTypeItem.spotTypeCode != "food"
      ) {
        this.spotTypeOnMenu = this.selectedSpotTypeItem.name;
        url = url + "&tag=" + this.selectedSpotTypeItem.spotTypeCode;
      }

      // console.log(url);
      axios
        .get(url)
        .then((response) => {
          this.spinner = false;
          if (response.data.error_message) {
            this.error = response.data.error_message;
            // console.log(response.data.error_message);
          } else {
            this.spots = response.data;
            // console.log(this.spots);
            var totalItems;

            // JSON.stringify(response)

            // setup on dropdown ui
          }
          // this.spinner = false;
        })
        .catch((error) => {
          this.spinner = false;
          // console.log(error.message);
          // this.spinner = false;
        });
    },
    search(query) {

      this.spinner = true;
            

      let limit = 100;
      var url =
        "https://glacial-wave-72174.herokuapp.com/search" +
        "?limit=" +
        limit +
        "&q=" +
        encodeURI(query);

     
      console.log(url);
      axios
        .get(url)
        .then((response) => {
          this.spinner = false;
          if (response.data.error_message) {
            this.error = response.data.error_message;
            // console.log(response.data.error_message);
          } else {
            this.spots = response.data;
            // console.log(this.spots);
            var totalItems;

            // JSON.stringify(response)

            // setup on dropdown ui
          }
          // this.spinner = false;
        })
        .catch((error) => {
          this.spinner = false;
          // console.log(error.message);
          // this.spinner = false;
        });

    },
    handleDropdownLocationsItem(city, locationItem) {
      this.selectedCityOnMenu = city;
      //this.locationNameOnMenu = city + " " + locationItem.name;
      this.currentAreaCode = locationItem.areaCode;
      this.selectedLocation = locationItem;
      this.fetchSpot();
      // $(".city-label").

      // $(".ui.dropdown").dropdown(
      //   "set text",
      //   this.selectedCityOnMenu + " " + locationItem.name
      // );

      // this.selectedLocation = this.taipeiAreaCode[command];
    },
    handleDropdownSpotTypeItem(spotTypeItem) {
      // console.log("hihi:" + spotTypeItem.spotTypeCode);
      this.spotTypeOnMenu = spotTypeItem.name;
      this.selectedSpotTypeItem = spotTypeItem;
      this.fetchSpot();

      // this.selectedLocation = this.taipeiAreaCode[command];
    },
    spotImageClick(spot) {
      window.open(spot.ig_post_url, "_blank");
      // console.log();
    },
    cickGoButton(item) {
      // window.open(item.map_url, "_blank");
      // console.log();
    },
    cickGoogleRatingButton(item) {
      window.open(item.map_url, "_blank");
      // console.log();
    },
    clickAddresLink(item) {
      window.open(item.map_url, "_blank");
    },

    clickOfficialSite(item) {
      if (item.officail_site != null) {
        window.open(item.officail_site, "_blank");
      } else {
      }
    },
    mouseOverSpotItem: function (data, index) {
      // console.log(index);
      $(".go-to-button").eq(index).css("background-color", "#fde467");
      // $(".item-header-text").eq(index).css("color", "#333333");
    },
    mouseOverOnFireButton: function () {
      console.log("hihi");
      $(".ui.fire-button").popup({
        inline: true,
      });
    },

    mouseLeaveSpotItem: function () {
      $(".go-to-button").css("background-color", "#e0e1e2");
      // $(".item-header-text").eq(index).css("color", "#123412");
    },
    aboustUsClick: function () {
      $(".about-us-model").modal("show");
      // $(".ui.modal").modal();
      // console.log("aboutsus");
    },
    searchClick: function () {
      
      var str = $('#myInput').val()
      console.log(str);
      this.search(str);
      // window.location.href = '/about';
    },
    feedbackClick: function () {
      window.open("https://forms.gle/qUHAxjZimwLr5aLB7 ", "_blank");
    },
  },

  goToInsLocation(cta) {
    // console.log(cta);
    window.open(cta, "_blank");
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 20px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.up-comming {
  /* color: #42b983; */
  padding-bottom: 0.5em;
}

.hidden.menu {
  display: none;
}

.masthead.segment {
  min-height: 300px;

  padding-top: 1em;
  padding-bottom: 2em;
  background-color: #fde467;
}
.masthead .logo.item img {
  margin-right: 1em;
}
.masthead .ui.menu .ui.button {
  margin-left: 0.5em;
}

.masthead .ui.button {
  margin-top: 3em;
  margin-bottom: 0em;
  margin-right: 1em;
}

/* .ui.button.login-buton {
  border: 1px solid #333333;
} */

.ui.selection.dropdown {
  min-width: 10em;
  min-height: 2em;
}

/* .more-button {
  padding-bottom: 5em ;
  padding-top: 2em;
} */

.masthead h1.ui.header {
  margin-top: 0.5em;
  margin-bottom: 0em;
  font-size: 3.5em;
  font-weight: normal;
}
.masthead h2 {
  font-size: 1.7em;
  font-weight: normal;
}

.ui.vertical.stripe {
  padding: 8em 0em;
}
.ui.vertical.stripe h3 {
  font-size: 2em;
}
.ui.vertical.stripe .button + h3,
.ui.vertical.stripe p + h3 {
  margin-top: 3em;
}
.ui.vertical.stripe .floated.image {
  clear: both;
}
.ui.vertical.stripe p {
  font-size: 1.33em;
}
.ui.vertical.stripe .horizontal.divider {
  margin: 3em 0em;
}

/* .ui.floating.label {
  position: absolute;
  z-index: 100;
  top: -1em;
  right: 100%;
  margin: 0 -1.5em 0 0 !important;
} */

.ui.list-table {
  padding-top: 3em;
}
.ui.list-table.item {
  padding-top: 1.5em;
}

/*
 .ui.items.link .item:hover {
 
  box-shadow: 0 0 4px hsl(0, 0%, 65%);
} */

.quote.stripe.segment {
  padding: 0em;
}
.quote.stripe.segment .grid .column {
  padding-top: 5em;
  padding-bottom: 5em;
}

.footer.segment {
  padding: 3em 0em;
}

.secondary.pointing.menu .toc.item {
  display: none;
}
.ui.fixed.menu {
  background-color: #fde467;
}

.ttrank-text {
  padding-left: 0.2rem;
}

.copy-right-item {
  padding-top: 5rem;
  padding-bottom: 2rem;
}

.top-menu-dropdown:hover {
  /* height: 35px; */
  background-color: white !important;
}

.top-menu-dropdown {
  /* height: 35px; */
  background-color: white !important;
  height: 46px;
}

/* .tag-label {
  padding-right: -10rem;
} */

.go-to-button {
  font-size: 30px;
  padding-top: -60px;
}

@media only screen and (max-width: 700px) {
  .ui.fixed.menu {
    display: none !important;
  }
  .secondary.pointing.menu .item,
  .secondary.pointing.menu .menu {
    display: none;
  }
  .secondary.pointing.menu .toc.item {
    display: block;
  }
  .masthead.segment {
    min-height: 30px;
  }
  .masthead h1.ui.header {
    font-size: 2em;
    margin-top: 1.5em;
  }
  .masthead h2 {
    margin-top: 0.5em;
    font-size: 1.5em;
  }

  .socail-link:hover {
    color: white;
  }

  /* .top-container {
    background-color: #FDE467;
  } */
  /* 
  .menu-button {
    padding-right: 5pt;
  } */
  /* .ui.min.images svg {

    width: 40px;
    height: 40px;
  } */
  /* #hide {
    padding-top: 3em;
    padding-bottom: 3em;
  } */

  /* Bottom right text */
  .bottom-right {
    position: absolute;
    bottom: 30px;
    right: 16px;
  }
  /* 
  .sidebar-dropdown-button {
    background-color: teal;
  } */

  /* .container {
  position: relative;
  text-align: center;
  color: white;
} */

  body {
    padding: 1em;
  }
}
</style>
