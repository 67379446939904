<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <HomeRank msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
// @ is an alias to /src
import HomeRank from '@/components/HomeRank.vue'

export default {
  name: 'Home',
  components: {
    HomeRank
  }
}
</script>
